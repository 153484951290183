import { h } from 'preact'

export default ({ regions = {} }) => (
  <section class='start page'>
    <section class='welcome'>
      <h1>Välkommen till Miller Konsult!</h1>

      <p>
        99,4 procent av det svenska näringslivet består av företag med 0-49 anställda. De allra flesta av dessa företag
        är ”ägarledda”, dvs åtminstone en av ägarna tar aktiv del i företagets verksamhet på daglig basis. Detta är
        också målgruppen för Millerkonsult; vår klientstock utgörs huvudsakligen av ägarledda aktiebolag med 1-20
        anställda.
      </p>

      <p>På Millerkonsult är vi tre medarbetare. Vårt kontor ligger i gamla Bromma, närmare bestämt i Ålsten.</p>

      <p>
        De tjänster vi tillhandahåller varierar: i ett antal fall svarar vi för hela företagets administration såsom
        löner, in-och utbetalningar, fakturering, bokslut, deklaration. Hos andra kunder begränsas vårt åtagande till
        exempelvis period- och årsbokslut och rådgivning i anslutning.
      </p>

      <p>
        Du väljer naturligtvis själv hur du vill använda våra resurser, och om behovet skulle öka eller minska över
        tiden, så är det inget som helst problem att ändra omfattningen.
      </p>
    </section>

    <section class='services'>
      <h1>Ekonomitjänster</h1>

      <section class='bokforing'>
        <h1>Löpande Bokföring</h1>
        <p>Vi arbetar med flertal affärssystemen, till exempel BL, Edison, Visma och Fortnox.</p>
      </section>

      <section class='bokslut'>
        <h1>Bokslut</h1>
        <p>Kortperiodiska bokslut, ofta med uppföljning mot budget, är ett vanligt behov.</p>
      </section>

      <section class='loneadministration'>
        <h1>Löneadministration</h1>
        <p>Vi kan svara för hela löneadministrationen, eller enbart de delar som ni vill ha hjälp med.</p>
      </section>

      <section class='arsredovisning'>
        <h1>Årsredovisning</h1>
        <p>
          Med hjälp av våra programvaror producerar vi väldokumenterade årsbokslut och årsredovisningar på ett
          kostnadseffektivt sätt.
        </p>
      </section>

      <section class='fakturering'>
        <h1>Fakturering</h1>
        <p>
          Flera kunder väljer att låta oss sköta faktureringen, vilket underlättar administrationen. Vi kan också
          hantera kravåtgärder.
        </p>
      </section>

      <section class='deklaration'>
        <h1>Deklaration</h1>
        <p>
          Utöver bolagets deklaration vill många kunder också ha hjälp med ägarnas deklarationer, där 3:12-reglerna är
          av central betydelse när det gäller avvägningar kring lön och aktieutdelning.
        </p>
      </section>
    </section>

    <section class='services'>
      <h1>Konsulttjänster</h1>
      <p>
        I Millerkonsult finns också kompetens för mer speciella frågor, såsom särskilda skattefrågor, aktieägaravtal,
        värdering, fusioner och andra skatte- och bolagsrättsliga frågor. Vi samarbetar också med en handfull experter
        inom skatterätt, HR och affärsjuridik, liksom auktoriserade revisorer från välrenommerade revisionsföretag.
      </p>
    </section>

    <section class='uppdrag'>
      <h1>Exempel på uppdrag</h1>
      <p>
        Utöver de tjänster som är traditionella för en redovisningsbyrå, har vi exempelvis arbetat med följande uppdrag:
      </p>

      <ul>
        <li>Stöd åt ägarna i försäljningsprocessen.</li>
        <li>Stöd åt företagsledningen i ägarlett företag inför kraftig expansion.</li>
        <li>Inhyrd CFO i flera bolag noterade på First North</li>
        <li>Styrelseroller i ägarledda företag.</li>
        <li>“Due Diligence” inför företagsförvärv.</li>
        <li>Flytt av legal hemvist från Storbritannien till Sverige.</li>
        <li>Stöd i samband med framtagande av årsredovisning enligt IFRS-reglerna för börsnoterat företag.</li>
      </ul>
    </section>
  </section>
)
