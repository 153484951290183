import { h, Component } from 'preact'

import { get } from 'lowline'

import Navigation from './Navigation'

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state.location = this.props.store.getState().location
  }

  getChildContext() {
    return {
      store: this.props.store,
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.store.subscribe(() => {
      const { location } = this.props.store.getState()

      if (!this.state.location || location.href !== this.state.location.href) {
        this.setState({ location })
      }
    })
  }

  componentWillUnmount() {
    this.unsubscribe()
  }

  componentDidUpdate() {
    const offset = get(this.props.store.getState(), 'location.state.scrollTop')

    window.scrollTo(0, offset || 0)
  }

  render({ store }, { location }) {
    const { pages, page } = store.getState()

    return (
      <div id='app'>
        <header class='main'>
          <div>
            <h1 class='logo'>
              <a href='/'>
                <span>Miller</span> <span>Konsult</span>
              </a>
            </h1>
            <Navigation routes={pages} location={location} />
          </div>
        </header>

        <main>{page && page.Component && <page.Component />}</main>

        <footer class='main'>
          <div class='top'>
            <div class='content' typeof='Organization'>
              <div property='name'>
                <span>Millerkonsult</span>
              </div>
              <p property='telephone'>0708 - 70 47 17</p>
              <p property='email'>
                <a href='mailto:{organization.email}'>kontakt@millerkonsult.se</a>
              </p>
            </div>
          </div>

          <div class='bottom'>
            <div class='content'>
              <p class='copyright'>Copyright © {new Date().getFullYear()} Miller Konsult AB. All rights reserved.</p>
              <p class='website-by'>
                <a href='/dataskyddspolicy'>Dataskyddspolicy</a>
              </p>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
