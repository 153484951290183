import { omit } from 'lowline'

export const SET_CURRENT_USER = 'SET_CURRENT_USER'

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    payload: user,
  }
}

export const LOGIN = 'LOGIN'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'

export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export function login(credentials) {
  return (dispatch) => {
    return fetch('/auth/login', {
      method: 'POST',
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json().then((json) => ({ body: json, location: res.headers.get('location') })))
      .then((res) => {
        const { body: json } = res

        if (json.error) {
          throw Object.assign(new Error(json.error.message), omit(json.error, 'message'))
        }

        dispatch({
          type: SET_CURRENT_USER,
          payload: json,
        })

        return res
      })
  }
}

export const REGISTER = 'REGISTER'

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'

export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export function register(credentials) {
  return (dispatch) => {
    return fetch('/auth/register', {
      method: 'POST',
      body: JSON.stringify(credentials),
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json().then((json) => ({ body: json, location: res.headers.get('location') })))
      .then((res) => {
        const { body: json } = res

        if (json.error) {
          throw Object.assign(new Error(json.error.message), omit(json.error, 'message'))
        }

        dispatch({
          type: REGISTER_SUCCESS,
          payload: json,
        })

        return res
      })
  }
}

export const LOGOUT = 'LOGOUT'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export function logout() {
  return (dispatch) => {
    fetch('/auth/logout', {
      credentials: 'same-origin',
    })
      .then(() =>
        dispatch({
          type: LOGOUT,
        }),
      )
      .catch((err) => console.error('error logging out', err))
  }
}
