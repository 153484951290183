import { SET_PAGE, UNSET_PAGE } from '../actions/page'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PAGE:
      return action.payload
    case UNSET_PAGE:
      return null
    default:
      return state
  }
}
