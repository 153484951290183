import { h } from 'preact'
import { map } from 'lowline'
import classNames from 'classnames'

const NavigationItem = ({ location, name, title, path, routes }) => (
  <li
    className={classNames({
      [name]: true,
      current: location && (location.pathname === path || (path !== '/' && location.pathname.startsWith(path))),
    })}
  >
    <a href={path} data-title={title}>
      <span>{title}</span>
    </a>
    {routes && (
      <ul>
        {map(
          routes,
          (route, p) =>
            route.nav !== false && <NavigationItem key={route.name} location={location} path={path + p} {...route} />,
        )}
      </ul>
    )}
  </li>
)

export default NavigationItem
