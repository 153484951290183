import { h } from 'preact'

export default (props) => (
  <section class='employee'>
    <h1>
      {props.givenName} {props.familyName}
    </h1>
    <p>
      {props.telephone}
      <br />
      <a href={`mailto:${props.email}`}>{props.email}</a>
    </p>
  </section>
)
