import './styles/main.scss'

import { h, render } from 'preact'

// function onRoute() {
//   if (window.ga) {
//     ga('set', 'page', window.location.pathname + window.location.search);
//     ga('send', 'pageview');
//   }
// }

// import Layout from './components/Layout';
import App from './components/App'

import store from './store'
import createRouter from './router'

const router = createRouter({ store })

router
  .start({
    trigger: true,
    run: true,
  })
  .then((result) => {
    render(<App store={store} />, null, document.getElementById('app'))
  })
  .catch((err) => console.error(err))
