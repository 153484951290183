import { h } from 'preact'

export default ({ regions }) => (
  <section class='nyhetsbrev page'>
    <header>
      <h1>Nyhetsbrev</h1>
    </header>
    <div class='content row'>
      <section class='cell'>
        <h1>Tidigare nyhetsbrev</h1>
        <ul>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/474A5F42724440594578464A59/414A5B4B7040445A437443415F4071'>
              November 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/474A5B47754647594178464659/414A5B4B7040445A437443415F4071'>
              Oktober 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/474B5E447548435C4775424559/414A5B4B7040445A437443415F4071'>
              September 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47445E46784346594673414759/414A5B4B7040445A437443415F4071'>
              Juni 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47445D47744245504475414B59/414A5B4B7040445A437443415F4071'>
              Maj 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47445941714844514375414459/414A5B4B7040445A437443415F4071'>
              April 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47455F4076464A5C4B78434A59/414A5B4B7040445A437443415F4071'>
              Mars 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47455B407747465C4378494259/414A5B4B7040445A437443415F4071'>
              Februari 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47465143754445514277494059/414A5B4B7040445A437443415F4071'>
              Januari 2022
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47465B4A7543405E4A73414059/414A5B4B7040445A437443415F4071'>
              December 2021
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47475042734146504B75484A59/414A5B4B7040445A437443415F4071'>
              November 2021
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47475A47754040514371424059/414A5B4B7040445A437443415F4071'>
              Oktober 2021
            </a>
          </li>
          <li>
            <a href='https://www.anpdm.com/newsletterweb/47405E417440425A4373494459/414A5B4B7040445A437443415F4071'>
              September 2021
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4545504B7647475C4778454059/414A5B4B7040445A437443415F4071'>
              December 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45455F437240465E4172444759/414A5B4B7040445A437443415F4071'>
              November 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4546504B73434B584179424259/414A5B4B7040445A437443415F4071'>
              Oktober 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45465A447947455B4273434259/414A5B4B7040445A437443415F4071'>
              September 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4547594A7044455B4673494059/414A5B4B7040445A437443415F4071'>
              Juni 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45405D4573424A584B78494059/414A5B4B7040445A437443415F4071'>
              Maj 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/454150407942435B4371464359/414A5B4B7040445A437443415F4071'>
              April 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45415D457245445D4377454B59/414A5B4B7040445A437443415F4071'>
              Mars 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4542504773444A514B78464759/414A5B4B7040445A437443415F4071'>
              Februari 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45425A4776424B5D4778474A59/414A5B4B7040445A437443415F4071'>
              Januari 2018
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45435146744142594170454159/414A5B4B7040445A437443415F4071'>
              December 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/45435D4B7746435B4374444A59/414A5B4B7040445A437443415F4071'>
              November 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/444A59447845455E4470484659/414A5B4B7040445A437443415F4071'>
              September 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/444450447244405F4178464659/414A5B4B7040445A437443415F4071'>
              Juni 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44445C46794041514B73434059/414A5B4B7040445A437443415F4071'>
              Maj 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/444459427044435B4279424A59/414A5B4B7040445A437443415F4071'>
              April 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44455A4473464B594075484259/414A5B4B7040445A437443415F4071'>
              Mars 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4446514B7445455F4771454559/414A5B4B7040445A437443415F4071'>
              Februari 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44465D4379424A5A4771414259/414A5B4B7040445A437443415F4071'>
              Januari 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44465D4379424A5A4771414259/414A5B4B7040445A437443415F4071'>
              Januari 2017
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44475144794741584374464059/414A5B4B7040445A437443415F4071'>
              December 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44475F4173444B504671464759/414A5B4B7040445A437443415F4071'>
              November 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/4447584B7143415F4576404B59/414A5B4B7040445A437443415F4071'>
              Oktober 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44405F4B794945584575424A59/414A5B4B7040445A437443415F4071'>
              September 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44415D447342425B4379434059/414A5B4B7040445A437443415F4071'>
              Juni 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/444159457240475F4077424759/414A5B4B7040445A437443415F4071'>
              Maj 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/44425E437944415B4A70464759/414A5B4B7040445A437443415F4071'>
              April 2016
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/434A5D4A7547415F4078424559/414A5B4B7040445A437443415F4071'>
              November 2015
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/434A5B4377414B504678404A59/414A5B4B7040445A437443415F4071'>
              Oktober 2015
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/434B5C447442455C4676454659/414A5B4B7040445A437443415F4071'>
              September 2015
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/43445E46794044594679474159/414A5B4B7040445A437443415F4071'>
              Juni 2015
            </a>
          </li>
          <li>
            <a href='http://www.anpdm.com/newsletterweb/43445A427148435B4774424B59/414A5B4B7040445A437443415F4071'>
              Maj 2015
            </a>
          </li>
        </ul>
      </section>
      <section class='signup cell'>
        <h1>Anmälan</h1>
        <p>
          Vill du ha ett eget nyhetsbrev? Det kostar ingenting; klicka bara på länken nedan och fyll i dina uppgifter,
          så kommer brevet en gång i månaden!
        </p>

        <ul>
          <li>
            <a href='http://www.anpdm.com/form/484A5F47764447514771/4447514470414B594671'>Prenumerera</a>
          </li>
        </ul>
      </section>
    </div>
  </section>
)
