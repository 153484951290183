export const SET_PAGE = 'SET_PAGE'

export function setPage(page) {
  return {
    type: SET_PAGE,
    payload: page,
  }
}

export const UNSET_PAGE = 'UNSET_PAGE'

export function unsetPage(page) {
  return {
    type: UNSET_PAGE,
    payload: page,
  }
}
