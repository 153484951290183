import { h } from 'preact'

import classNames from 'classnames'
import { SingleCheckbox, Form, Input, TextArea } from 'comkit-preact'
import isEmail from 'validator/lib/isEmail'

export default class ContactForm extends Form {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)

    this.state = {
      sending: false,
      sent: false,
    }
  }

  onSubmit(e) {
    e.preventDefault()

    if (!this.validate(true, true)) return false

    const time = Date.now()

    this.setState({
      sending: true,
    })

    fetch('/kontakt', {
      method: 'POST',
      body: JSON.stringify(this.toJSON()),
      headers: {
        'content-type': 'application/json',
        accepts: 'applicaton/json',
      },
    })
      .catch(() => {
        this.setState({
          sending: false,
        })
      })
      .then((res) => {
        this.setState({
          sent: true,
          time: Date.now() - time,
        })
      })
  }

  render(props, { time, sent, sending }) {
    return (
      (sent && (
        <section class='response cell'>
          <h1>Tack för ditt mail!</h1>
          <p>Vi återkommer så snart som möjligt.</p>
        </section>
      )) || (
        <form onSubmit={this.onSubmit} class={classNames({ cell: true, sending })} noValidate>
          <h1>Kontakta oss</h1>
          <Input disabled={sending} type='text' name='name' placeholder='Namn' required />
          <Input
            disabled={sending}
            type='text'
            name='email'
            placeholder='Email'
            tests={[[isEmail, 'Not a valid email']]}
            required
          />
          <TextArea disabled={sending} name='message' placeholder='Message' required />
          <SingleCheckbox required>
            Jag godkänner att Millerkonsult lagrar och använder personlig information såsom namn och mailadress , för
            att komma i kontakt med mig. Data delas inte med någon tredje part och används inte i annat syfte. Alla
            personuppgifter raderas om Millerkonsult inte får återkoppling inom rimlig tid (observera att de kan finnas
            kvar längre i backup-system)
          </SingleCheckbox>
          <button disabled={sending}>Skicka</button>
        </form>
      )
    )
  }
}
