import { combineReducers } from 'redux'

import auth from './auth'
import employees from './employees'
import location from './location'
import organization from './organization'
import page from './page'
import pagination from './pagination'

export default combineReducers({
  auth,
  employees,
  location,
  organization,
  pages: (state = null) => state,
  page,
  pagination,
})
