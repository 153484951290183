import { h } from 'preact'

import isEmail from 'validator/lib/isEmail'
import { Form, SingleCheckbox, Input, Message } from 'comkit-preact'

import { login } from '../actions/auth'

export default class LoginForm extends Form {
  constructor(...args) {
    super(...args)

    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit(e) {
    e.preventDefault()

    const store = this.context.store

    if (!this.validate(true, true)) return false

    const data = this.toJSON()

    store.dispatch(login(data)).then(
      ({ location }) => {
        window.location.href = location || '/'
      },
      (error) => {
        this.setState({ error })
      },
    )
  }

  render() {
    const { error } = this.state

    return (
      <section class='login-form'>
        <h1>Log In</h1>

        <form onSubmit={this.onSubmit} action='/auth/local' method='POST' noValidate>
          {error && <Message type='error' heading='Oops!' body={error.message} />}
          <Input
            type='email'
            placeholder='Email address'
            name='email'
            tests={[[isEmail, 'Not a valid email']]}
            required
          />
          <Input type='password' placeholder='Password' name='password' required />
          <SingleCheckbox type='checkbox' name='remember' /> Remember me
          <div class='buttons'>
            <button type='submit'>Log In</button>
          </div>
        </form>

        <p>
          <a href='/forgot'>Forgot your password?</a>
        </p>

        <div class='close' />
      </section>
    )
  }
}
