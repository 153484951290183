import { RECEIVE_EMPLOYEES, REMOVE_EMPLOYEES } from '../actions/employees'

export default (state = null, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEES:
      return action.payload || []
    case REMOVE_EMPLOYEES:
      return null
    default:
      return state
  }
}
