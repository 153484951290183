import { h } from 'preact'

export default () => (
  <div class='dataskyddspolicy page'>
    <section class='content'>
      <h1>Dataskyddspolicy</h1>
      <p>Vår dataskyddspolicy följer den nya lagen (GDPR 25 maj 2018).</p>
      <p>
        Vi värnar om din och ditt företags integritet. I det efterföljande avses med ”du”, ”dig”, ”dig själv”, ”din”
        eller ”ditt” du som person och/eller ditt företag och dess anställda, kunder och leverantörer. Denna
        dataskyddspolicy förklarar hur vi samlar in och använder dina personuppgifter. Den beskriver också dina
        rättigheter gentemot oss och hur du kan göra dina rättigheter gällande. Du kan alltid kontakta oss vid frågor
        kring dataskydd genom att skicka ett e-postmeddelande till oss på info@millerkonsult.se
      </p>
      <p>
        Genom att använda våra tjänster, accepterar du vår Dataskyddspolicy och vår behandling av dina personuppgifter.
        Du godkänner också att vi använder elektroniska kommunikations-kanaler för att skicka information till dig,
        såsom SMS, e-post och månadsbrev. Det är viktigt att du läser och förstår vår dataskyddspolicy innan du använder
        våra tjänster.
      </p>
      <h2>Vilken information samlar vi in?</h2>
      <p>
        Information som du ger till oss. Du kan direkt eller indirekt komma att ge oss information om dig själv på ett
        antal olika sätt, såsom när du anlitar oss som leverantör av redovisningstjänster och rådgivning i anslutning
        härtill. Vi kan också samla in information om dig. Detta kan vara:
      </p>
      <ul>
        <li>
          Person- och kontaktinformation - namn, födelsedatum, personnummer, faktura- och leveransadress, e-postadress,
          mobiltelefonnummer, etc.
        </li>
        <li>Betalningsinformation - t.ex. bankkonto- eller IBAN-nummer.</li>
        <li>Inloggningsuppgifter - såsom internetbank, Skatteverket, affärs- och lönesystem.</li>
        <li>
          Person- och kontaktinformation - namn, födelsedatum, personnummer, e-postadress, mobiltelefon-nummer, etc.
        </li>
        <li>
          Historisk information - Informationen du ger oss, såsom din finansiella information, är generellt sett
          nödvändig för att ingå ett avtalsförhållande med oss, medan den övriga informationen vi samlar in generellt
          sett är nödvändig för andra syften, såsom beskrivet nedan.
        </li>
      </ul>
      <h2>Vad gör vi med din information?</h2>
      <table>
        <thead>
          <tr>
            <th>Syfte med behandlingen</th>
            <th>Laglig grund för behandlingen</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>För att bekräfta din identitet och verifiera dina person- och kontaktuppgifter</td>
            <td>Utföra våra kontraktuella åtaganden gentemot dig</td>
          </tr>
          <tr>
            <td>För att kunna leverera de tjänster du önskar få utförda av oss.</td>
            <td>Utföra våra kontraktuella åtaganden gentemot dig</td>
          </tr>
          <tr>
            <td>För att utföra riskanalys, förhindra bedrägerier och riskhantering.</td>
            <td>Följa tillämplig lagstiftning och andra berättigade intressen</td>
          </tr>
          <tr>
            <td>
              För att följa tillämplig lagstiftning, såsom bokförings- och aktiebolagslag, lag om åtgärder mot
              penningtvätt.
            </td>
            <td>Följa tillämplig lagstiftning</td>
          </tr>
        </tbody>
      </table>
      <strong>Kommunicera med dig.</strong> Millerkonsult kan komma att använda dina data för brev, SMS, e-post och
      utskick av månadsbrev.
      <h2>Vilka kan vi komma att dela din information till?</h2>
      <p>
        <strong>Leverantörer och underleverantörer</strong>. Vi kan komma att dela dina personuppgifter till dina eller
        våra leverantörer eller underleverantörer för utförandet av våra åtaganden gentemot dig och för andra syften som
        framgår i den här Dataskyddspolicyn.
      </p>
      <p>
        <strong>Myndigheter</strong>. Millerkonsult kan komma att lämna nödvändig information till myndigheter såsom
        Skatteverket eller andra myndigheter om vi är skyldiga att göra det enligt lag eller om du har godkänt att vi
        gör det. Ett exempel på laglig skyldighet att lämna information är för åtgärder mot penningtvätt och
        terroristfinansiering.
      </p>
      <h2>Vad vi INTE kommer att göra med din data.</h2>
      <p>Vi kommer inte att sälja dina personuppgifter till tredje part om vi inte har ditt tillstånd att göra så.</p>
      <h2>Var behandlar vi dina personuppgifter?</h2>
      <p>
        Då vi är fast beslutna att alltid skydda din data, kommer Millerkonsult att vidta alla rimliga legala, tekniska
        och organisatoriska åtgärder för att säkerställa att din data hanteras säkert och med en adekvat skyddsnivå
        jämförbar med och i samma nivå som det skydd som erbjuds inom EU/EES.
      </p>
      <h2>Hur länge sparar vi dina personuppgifter?</h2>
      <p>
        Vi sparar dina data endast så länge som det är nödvändigt för att utföra våra åtaganden gentemot dig och så
        länge det krävs enligt lagstadgade lagringstider. När vi sparar dina data för andra syften än för våra
        kontraktuella åtaganden, t.ex. för att uppfylla krav på åtgärder mot penningtvätt, bokföring och regulatoriska
        kapitalkrav, sparar vi dessa endast så länge som det är nödvändigt och/eller lagstadgat för respektive syfte.
      </p>
      <h2>Dina rättigheter till tillgång, rättelse och radering</h2>
      <p>
        <strong>Rätt att få tillgång till din data</strong>. Du kan begära en kopia av de personuppgifter vi har om dig.
      </p>
      <p>
        <strong>Rätt till rättelse</strong>. Du har rätt att begära korrigering av felaktig eller icke-komplett
        information om dig och Millerkonsult är skyldigt att vidtaga rättelse.
      </p>
      <p>
        <strong>Radering av uppgifter</strong>. Du har rätt att begära radering av dina personuppgifter för de fall att
        dessa inte längre är nödvändig för det syfte den blev insamlad för. Det kan dock finnas legala skyldigheter som
        hindrar oss från att omedelbart radera delar därav. Dessa skyldigheter kommer från t.ex. bokförings- och
        skattelagstiftning, bank- och penningtvättslagstiftning. Vad vi då gör är att blockera den data som vi är
        skyldiga att spara, från att kunna användas till andra syften än att uppfylla sådana legala skyldigheter.
      </p>
      <h2>Kontakta oss</h2>
      <p>
        Vi är registrerade hos Bolagsverket som Lotta Miller Konsult i Bromma AB med organisations-nummer 556340-6528,
        under adress Virvelvindsvägen 52, 167 67 BROMMA. E-postadressen är{' '}
        <a href='mailto:info@millerkonsult.se'>info@millerkonsult.se</a>
      </p>
    </section>
  </div>
)
