import { SET_LOCATION } from '../actions/location'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_LOCATION:
      return action.payload
    default:
      return state
  }
}
