import { Router } from 'nooks'

import { snakeCase, without, forEach } from 'lowline'
import { get } from 'rek'
import { batchActions } from 'redux-batched-actions'

import { setCurrentUser } from './actions/auth'
import { setPagination, unsetPagination } from './actions/pagination'
import { setLocation } from './actions/location'
import { setPage, unsetPage } from './actions/page'

import pages from './pages'

function remember(attrs) {
  return {
    scrollTop: window.pageYOffset,
  }
}

// eslint-disable-next-line
const urlRegex = /(https?:)\/\/([^:\/]+)(:[0-9]+)?([^?]*)(\?.*)?/

function parseUrl(url) {
  const result = url.match(urlRegex)
  const [href, protocol, hostname, port, pathname, search] = result

  const obj = {
    href: href.endsWith('/') ? href : href + '/',
    protocol,
    host: port ? hostname + port : hostname,
    hostname,
    pathname: pathname || '/',
    port: port ? port.slice(1) : '',
    search: search && search.length > 1 ? search : '',
  }

  return obj
}

function createReceiver(key, payload) {
  return {
    type: `RECEIVE_${snakeCase(key).toUpperCase()}`,
    payload,
    receivedAt: new Date(),
  }
}

function createRemover(key) {
  return { type: `REMOVE_${snakeCase(key).toUpperCase()}` }
}

export default ({ store }) => {
  const router = new Router({
    // realm: /\/(?!admin)/,
    root: '/admin',
    remember,
    scrollRestoration: 'manual',
  })

  let currentKeys = []

  const ignore = ['organization', 'page', 'pagination', 'auth', 'location']

  router.use((ctx, next) => {
    return get(ctx.url, { raw: true }).then((res) => {
      // TODO use window.location or do this, only did in case of redirects
      const location = parseUrl(res.url)

      return res.json().then((json) => {
        Object.assign(ctx, {
          actions: [],
          location: location,
          redirected: res.redirected,
          response: res,
          status: res.status,
          url: location.pathname,
        })

        Object.assign(ctx.state, json, {
          location,
        })

        next()
      })
    })
  })

  // set location
  router.use((ctx, next) => {
    ctx.actions.push(setLocation(ctx.location))
    next()
  })

  // set page
  router.use((ctx, next) => {
    const page = pages[ctx.location.pathname]

    if (page) {
      ctx.actions.push(setPage(page))
    } else {
      ctx.actions.push(unsetPage())
    }

    next()
  })

  // forEach(routes, (route, path) => {
  //   route = Object.assign({
  //     path,
  //   }, route)

  //   router.use(path, (ctx, next) => {
  //     ctx.route = route

  //     next()
  //   })
  // })

  router.use((ctx, next) => {
    const keys = []

    forEach(ctx.state, (items, key) => {
      if (!ignore.includes(key)) {
        ctx.actions.push(createReceiver(key, items))
        keys.push(key)
      }
    })

    // add remove action for all currentKeys that are not in keys
    without(currentKeys, ...keys).forEach((key) => {
      ctx.actions.push(createRemover(key))
    })

    if (ctx.state.auth && ctx.state.auth.user) {
      ctx.actions.push(setCurrentUser(ctx.state.auth.user))
    }

    ctx.actions.push(ctx.state.pagination ? setPagination(ctx.state.pagination) : unsetPagination())

    currentKeys = keys

    next()
  })

  router.use((ctx, next) => {
    const actions = ctx.actions || []

    store.dispatch(batchActions(actions))

    next()
  })

  return router
}
// import { Router } from 'nooks'

// import { without, forEach } from 'lowline'
// import { batchActions } from 'redux-batched-actions'

// import { receiveEmployees, removeEmployees } from './actions/employees'
// import { setPagination, unsetPagination } from './actions/pagination'
// import { setLocation } from './actions/location'

// import pages from './pages'

// import store from './store'

// const receivers = {
//   employees: receiveEmployees,
// }

// const removers = {
//   employees: removeEmployees,
// }

// let currentKeys = []

// function remember () {
//   return {
//     scrollTop: window.pageYOffset,
//   }
// }

// function pre (ctx, done) {
//   const { url } = ctx

//   fetch(url, {
//     credentials: 'same-origin',
//     headers: {
//       'X-Requested-With': 'XMLHttpRequest',
//       accept: 'application/json',
//     },
//   }).then((res) => res.json())
//     .then((json) => {
//       const keys = []
//       const actions = []

//       forEach(json, (items, key) => {
//         if (key in receivers) {
//           actions.push(receivers[key](items))
//           keys.push(key)
//         }
//       })

//       // add remove action for all currentKeys that are not in keys
//       without(currentKeys, ...keys).forEach((key) => {
//         actions.push(removers[key]())
//       })

//       actions.push(json.pagination ? setPagination(json.pagination) : unsetPagination())

//       currentKeys = keys

//       ctx.actions = actions

//       done()
//     })
// }

// function finish (ctx, done) {
//   const actions = ctx.actions || []

//   actions.push(setLocation(ctx))

//   store.dispatch(batchActions(actions))

//   done()
// }

// export default new Router({
//   routes: pages,
//   remember,
//   pre,
//   finish,
//   scrollRestoration: 'manual',
// })
