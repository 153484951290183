import { SET_PAGINATION, UNSET_PAGINATION } from '../actions/pagination'

export default (state = null, action) => {
  switch (action.type) {
    case SET_PAGINATION:
      return Object.assign({}, state, action.payload)
    case UNSET_PAGINATION:
      return null
    default:
      return state
  }
}
