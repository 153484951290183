import { h } from 'preact'

import ContactForm from '../components/ContactForm'
import EmployeeContactDetails from '../components/EmployeeContactDetails'

export default (props, context) => {
  const { employees } = context.store.getState()

  return (
    <section class='kontakt page'>
      <header>
        <h1>Kontakt</h1>
      </header>

      <div class='content row'>
        <section class='details cell'>
          <h1>Kontaktuppgifter</h1>

          {employees && employees.map((employee) => <EmployeeContactDetails {...employee} />)}
        </section>

        <section>
          <ContactForm id='contact-form' />
        </section>
      </div>
    </section>
  )
}
