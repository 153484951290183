import { h } from 'preact'

import EmployeeDescription from '../components/EmployeeDescription'

export default (props, context) => {
  const { employees } = context.store.getState()

  return (
    <section class='medarbetare page'>
      <header>
        <h1>Medarbetare</h1>
      </header>
      {employees && employees.map((employee) => <EmployeeDescription {...employee} />)}
    </section>
  )
}
