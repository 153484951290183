export const RECEIVE_EMPLOYEES = 'RECEIVE_EMPLOYEES'

export function receiveEmployees(json) {
  return {
    type: RECEIVE_EMPLOYEES,
    payload: json,
    receivedAt: Date.now(),
  }
}

export const REMOVE_EMPLOYEES = 'REMOVE_EMPLOYEES'

export function removeEmployees() {
  return {
    type: REMOVE_EMPLOYEES,
  }
}
