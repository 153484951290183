import { h, Component } from 'preact'
import { map, bindAll } from 'lowline'
import classNames from 'classnames'

import NavigationItem from './NavigationItem'

export default class Navigation extends Component {
  constructor(options = {}) {
    super(options)

    this.state = { visible: false }

    bindAll(this, ['hide', 'toggle'])
  }

  toggle(force) {
    const next = typeof force === 'boolean' ? force : !this.state.visible

    document.body.classList.toggle('shift', next)

    this.setState({
      visible: next,
    })
  }

  hide() {
    this.toggle(false)
  }

  render({ routes, location }, { visible }) {
    return (
      <nav class={classNames({ visible })}>
        <button onClick={this.toggle} />
        <ul onClick={this.hide}>
          {map(
            routes,
            (route, path) =>
              route.nav !== false && <NavigationItem key={route.name} location={location} path={path} {...route} />,
          )}
        </ul>
        <div onClick={this.hide} class='cover' />
      </nav>
    )
  }
}
