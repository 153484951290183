import { h } from 'preact'

export default (props) => (
  <section class='employee'>
    <h1>
      {props.givenName} {props.familyName}
    </h1>
    <div dangerouslySetInnerHTML={{ __html: props.description }} />
  </section>
)
