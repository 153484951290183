import Index from './pages/Index'
import Medarbetare from './pages/Medarbetare'
import Nyhetsbrev from './pages/Nyhetsbrev'
import Kontakt from './pages/Kontakt'
import Dataskyddspolicy from './pages/Dataskyddspolicy'
import Login from './pages/Login'

export default {
  '/': {
    name: 'start',
    title: 'Start',
    description: 'Första sidan',
    Component: Index,
  },

  '/medarbetare': {
    name: 'medarbetare',
    title: 'Medarbetare',
    Component: Medarbetare,
  },

  '/nyhetsbrev': {
    name: 'nyhetsbrev',
    title: 'Nyhetsbrev',
    Component: Nyhetsbrev,
  },

  '/kontakt': {
    name: 'kontakt',
    title: 'Kontakt',
    Component: Kontakt,
  },

  '/dataskyddspolicy': {
    name: 'dataskyddspolicy',
    title: 'Dataskyddspolicy',
    Component: Dataskyddspolicy,
    nav: false,
  },

  '/login': {
    name: 'login',
    title: 'Login',
    Component: Login,
    nav: false,
  },
}
