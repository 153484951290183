export const SET_PAGINATION = 'SET_PAGINATION'

export function setPagination(obj) {
  return {
    type: SET_PAGINATION,
    payload: obj,
  }
}

export const UNSET_PAGINATION = 'UNSET_PAGINATION'

export function unsetPagination(obj) {
  return {
    type: UNSET_PAGINATION,
    payload: obj,
  }
}
