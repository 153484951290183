import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { enableBatching } from 'redux-batched-actions'
import { applyMiddleware, createStore, compose } from 'redux'

import pages from './pages'

import rootReducer from './reducers/root'

export function getDefaultMiddleware(thunkInjection) {
  return [thunk.withExtraArgument(thunkInjection)]
}

function create(options = {}) {
  const {
    thunkInjection,
    middleware = getDefaultMiddleware(thunkInjection),
    devTools = true,
    preloadedState = {
      pages,
    },
    enhancers = [],
  } = options

  const middlewareEnhancer = applyMiddleware(...middleware)
  const storeEnhancers = [middlewareEnhancer, ...enhancers]
  const finalCompose = devTools ? composeWithDevTools : compose
  const composedEnhancer = finalCompose(...storeEnhancers)
  const store = createStore(enableBatching(rootReducer), preloadedState, composedEnhancer)

  return store
}

export default create()
